var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"visibility-planning-setting"},[(_vm.initLoading)?_c('div',{staticClass:"loader-content"},[_c('v-progress-circular',{staticClass:"ml-3",attrs:{"indeterminate":"","color":"#5C2DD3"}})],1):_c('div',[_c('v-row',{staticClass:"content-regie"},[_c('v-col',{staticClass:"groupe",attrs:{"col":"","xs":"12","sm":"6","md":"5"}},[_c('div',{staticClass:"title color-crm bold-700 font-text mt-2"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Filiales' : 'Filiales')+" ")]),(_vm.teamsfiliale && _vm.teamsfiliale.length)?_c('div',{staticClass:"list-users"},[_c('v-list',{staticClass:"list-ensemble",attrs:{"dense":""}},_vm._l((_vm.teamsfiliale),function(filiale,index){return _c('v-list-group',{key:index,staticClass:"list-ensemble-item",attrs:{"color":"#5C2DD3","no-action":"","sub-group":"","value":index.id},on:{"click":function($event){filiale.visible = !filiale.visible}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{staticClass:"font-text font-sz-14 bold-700"},[_vm._v(" "+_vm._s(filiale.name)+" ")])])],1)]},proxy:true}],null,true)},[_c('v-card',{staticClass:"card-list border-top-solid",model:{value:(filiale.visible),callback:function ($$v) {_vm.$set(filiale, "visible", $$v)},expression:"filiale.visible"}},[(
                    filiale.depots &&
                      filiale.depots.data &&
                      filiale.depots.data.length
                  )?_c('div',{staticClass:"list-depot"},[_c('v-list',{staticClass:"list-ensemble-depot",attrs:{"dense":"","flat":""}},_vm._l((filiale.depots.data),function(depot,index){return _c('v-list-item',{key:depot.id,class:{
                        'border-bottom-solid':
                          index < filiale.depots.data.length - 1
                      },attrs:{"button":"","link":"","value":depot.id},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleClickDepots(depot.id)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"capitalize color-crm",class:{
                              'color-black': _vm.selectedDepot != depot.id
                            }},[_vm._v(" "+_vm._s(depot.name))])])],1),(_vm.selectedDepot == depot.id)?_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"color-crm",attrs:{"icon":"check"}})],1):_vm._e()],1)}),1)],1):_c('div',{staticClass:"Aucundepots text-center mt-1 mb-1"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Aucun dépôt' : 'No deposit')+" ")])])],1)}),1)],1):_c('div',{staticClass:"color-crm font-text font-sz-14 text-center mt-3"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Aucun Filiale' : 'No Filiale')+" ")])]),_c('v-col',{staticClass:"utilisateur border-left-solid",attrs:{"col":"","xs":"12","sm":"6","md":"7"}},[_c('div',{staticClass:"title block-flex-align-space-between"},[_c('div',{staticClass:"color-crm bold-700 font-text mt-2"},[_vm._v(" "+_vm._s(_vm.$t('utilisateurs'))+" ")]),_c('div',[(_vm.loading)?_c('v-progress-circular',{staticClass:"sz-loader-icon",attrs:{"indeterminate":"","color":"#5C2DD3"}}):_vm._e()],1)]),_c('div',{staticClass:"bloc-select-multi"},[_c('v-autocomplete',{staticClass:"mb-0 select-users",attrs:{"clearable":true,"color":"#5C2DD3","placeholder":_vm.$i18n.locale === 'fr'
                ? 'Rechercher un utilisateur'
                : 'Search user',"item-color":"#5C2DD3","loading":_vm.loading,"items":_vm.computedAvailableUsers || [],"custom-label":_vm.nameWithLang,"persistent-placeholder":true,"return-object":"","label":_vm.$t('utilisateurs'),"item-text":_vm.nameWithLang,"dense":"","item-value":"id","auto-focus":false,"no-data-text":_vm.$i18n.locale === 'fr'
                ? 'Aucune utilisateurs trouvée'
                : 'No users found',"outlined":"","menu-props":{
              bottom: true,
              offsetY: true
            }},on:{"input":_vm.handleSelect},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}}),(_vm.loading)?_c('div',{staticClass:"color-crm font-sz-12 text-center font-text mt-2"},[_vm._v(" "+_vm._s(_vm.$t('loading'))+" ")]):_vm._e(),(_vm.error && !_vm.loading)?_c('div',{staticClass:"error-msg"},[(Array.isArray(_vm.error))?_c('ul',_vm._l((_vm.error),function(e,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(e)+" ")])}),0):_c('span',[_vm._v(_vm._s(_vm.error))])]):_vm._e(),(_vm.selectedDepot && _vm.selectedUsers && _vm.selectedUsers.length)?_c('div',{staticClass:"addListUsers"},[(_vm.selectedUsers && _vm.selectedUsers.length)?_c('div',{staticClass:"list-users mt-2",class:{
                'refact-height': _vm.selectedDepot && _vm.selectedDepot.length
              }},[_c('v-list',{staticClass:"list-item-user"},_vm._l((_vm.selectedUsers),function(user,index){return _c('v-list-item',{key:user.id,staticClass:"custom-list-group custom-list-group-vue",class:{
                    'border-top-solid': index == 0
                  },attrs:{"button":""}},[_c('v-list-item-content',[_c('div',{staticClass:"block-flex-align-space-between"},[_c('v-list-item-title',[_c('span',{staticClass:"font-sz-14 font-text bold-600 mr-2"},[_vm._v(" "+_vm._s(user.first_name)+" "+_vm._s(user.last_name))]),_c('strong',{staticClass:"color-crm font-sz-12 font-text"},[_vm._v("("+_vm._s(_vm._f("typeFormat")(user.type))+")")]),_c('br'),(user.team)?_c('strong',{staticClass:"color-crm font-sz-12 font-text"},[_vm._v(_vm._s(user.team))]):_c('strong',{staticClass:"color-crm font-sz-12 font-text"},[_vm._v("(sans équipe)")])])],1)]),_c('v-list-item-action',[_c('v-checkbox',{staticClass:"input-checkbox msg-error mt-2",attrs:{"value":user.can_see,"disabled":user.disabled === 1,"color":"#5C2DD3","title":_vm.$i18n.locale === 'fr' ? 'Lire' : 'Read',"hide-details":""},on:{"change":function($event){return _vm.handleChangeChekboxRead(user.id)}},model:{value:(user.can_see),callback:function ($$v) {_vm.$set(user, "can_see", $$v)},expression:"user.can_see"}})],1),_c('v-list-item-action',[_c('v-checkbox',{staticClass:"input-checkbox msg-error mt-2",attrs:{"value":user.can_edit,"disabled":user.disabled === 1,"color":"#5C2DD3","hide-details":"","title":_vm.$i18n.locale === 'fr' ? 'Ecrire' : 'Write'},on:{"change":function($event){return _vm.handleChangeChekboxWrite(user)}},model:{value:(user.can_edit),callback:function ($$v) {_vm.$set(user, "can_edit", $$v)},expression:"user.can_edit"}})],1)],1)}),1)],1):_vm._e()]):_vm._e(),(
              _vm.selectedDepot &&
                (!_vm.selectedUsers || !_vm.selectedUsers.length) &&
                !_vm.loading
            )?_c('div',{staticClass:"color-crm font-text font-sz-14 text-center mt-3"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Aucun utilisateur' : 'No user')+" ")]):_vm._e()],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }