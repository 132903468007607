<template>
  <div class="visibility-planning-setting">
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-row class="content-regie">
        <v-col class="groupe" col xs="12" sm="6" md="5">
          <div class="title color-crm bold-700 font-text mt-2">
            {{ $i18n.locale === 'fr' ? 'Filiales' : 'Filiales' }}
          </div>
          <div class="list-users" v-if="teamsfiliale && teamsfiliale.length">
            <v-list dense class="list-ensemble">
              <v-list-group
                color="#5C2DD3"
                class="list-ensemble-item"
                no-action
                sub-group
                @click="filiale.visible = !filiale.visible"
                v-for="(filiale, index) in teamsfiliale"
                :key="index"
                :value="index.id"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>
                      <div class="font-text font-sz-14 bold-700">
                        {{ filiale.name }}
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-card
                  class="card-list border-top-solid"
                  v-model="filiale.visible"
                >
                  <div
                    v-if="
                      filiale.depots &&
                        filiale.depots.data &&
                        filiale.depots.data.length
                    "
                    class="list-depot"
                  >
                    <v-list dense class="list-ensemble-depot" flat>
                      <v-list-item
                        button
                        link
                        v-for="(depot, index) in filiale.depots.data"
                        :key="depot.id"
                        :value="depot.id"
                        @click.prevent.stop="handleClickDepots(depot.id)"
                        :class="{
                          'border-bottom-solid':
                            index < filiale.depots.data.length - 1
                        }"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            ><span
                              class="capitalize color-crm"
                              :class="{
                                'color-black': selectedDepot != depot.id
                              }"
                            >
                              {{ depot.name }}</span
                            ></v-list-item-title
                          >
                        </v-list-item-content>
                        <v-list-item-icon v-if="selectedDepot == depot.id">
                          <font-awesome-icon class="color-crm" icon="check" />
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div v-else class="Aucundepots text-center mt-1 mb-1">
                    {{ $i18n.locale === 'fr' ? 'Aucun dépôt' : 'No deposit' }}
                  </div>
                </v-card>
              </v-list-group>
            </v-list>
          </div>
          <div class="color-crm font-text font-sz-14 text-center mt-3" v-else>
            {{ $i18n.locale === 'fr' ? 'Aucun Filiale' : 'No Filiale' }}
          </div>
        </v-col>
        <v-col class="utilisateur border-left-solid" col xs="12" sm="6" md="7">
          <div class="title block-flex-align-space-between">
            <div class="color-crm bold-700 font-text mt-2">
              {{ $t('utilisateurs') }}
            </div>
            <div>
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="#5C2DD3"
                class="sz-loader-icon"
              ></v-progress-circular>
            </div>
          </div>
          <div class="bloc-select-multi">
            <v-autocomplete
              :clearable="true"
              color="#5C2DD3"
              :placeholder="
                $i18n.locale === 'fr'
                  ? 'Rechercher un utilisateur'
                  : 'Search user'
              "
              item-color="#5C2DD3"
              @input="handleSelect"
              v-model="selectedUser"
              :loading="loading"
              :items="computedAvailableUsers || []"
              :custom-label="nameWithLang"
              :persistent-placeholder="true"
              return-object
              :label="$t('utilisateurs')"
              class="mb-0 select-users"
              :item-text="nameWithLang"
              dense
              item-value="id"
              :auto-focus="false"
              :no-data-text="
                $i18n.locale === 'fr'
                  ? 'Aucune utilisateurs trouvée'
                  : 'No users found'
              "
              outlined
              :menu-props="{
                bottom: true,
                offsetY: true
              }"
            >
            </v-autocomplete>
            <div
              v-if="loading"
              class="color-crm font-sz-12 text-center font-text mt-2"
            >
              {{ $t('loading') }}
            </div>
            <div v-if="error && !loading" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
            <div
              class="addListUsers"
              v-if="selectedDepot && selectedUsers && selectedUsers.length"
            >
              <div
                class="list-users mt-2"
                :class="{
                  'refact-height': selectedDepot && selectedDepot.length
                }"
                v-if="selectedUsers && selectedUsers.length"
              >
                <v-list class="list-item-user">
                  <v-list-item
                    button
                    v-for="(user, index) in selectedUsers"
                    :key="user.id"
                    class="custom-list-group custom-list-group-vue"
                    :class="{
                      'border-top-solid': index == 0
                    }"
                  >
                    <v-list-item-content>
                      <div class="block-flex-align-space-between">
                        <v-list-item-title
                          ><span class="font-sz-14 font-text bold-600 mr-2">
                            {{ user.first_name }} {{ user.last_name }}</span
                          >
                          <strong class="color-crm font-sz-12 font-text"
                            >({{ user.type | typeFormat }})</strong
                          ><br />
                          <strong
                            class="color-crm font-sz-12 font-text"
                            v-if="user.team"
                            >{{ user.team }}</strong
                          >
                          <strong class="color-crm font-sz-12 font-text" v-else
                            >(sans équipe)</strong
                          >
                        </v-list-item-title>
                      </div>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        class="input-checkbox msg-error mt-2"
                        :value="user.can_see"
                        v-model="user.can_see"
                        :disabled="user.disabled === 1"
                        @change="handleChangeChekboxRead(user.id)"
                        color="#5C2DD3"
                        :title="$i18n.locale === 'fr' ? 'Lire' : 'Read'"
                        hide-details
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-checkbox
                        class="input-checkbox msg-error mt-2"
                        v-model="user.can_edit"
                        :value="user.can_edit"
                        :disabled="user.disabled === 1"
                        @change="handleChangeChekboxWrite(user)"
                        color="#5C2DD3"
                        hide-details
                        :title="$i18n.locale === 'fr' ? 'Ecrire' : 'Write'"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            <div
              class="color-crm font-text font-sz-14 text-center mt-3"
              v-if="
                selectedDepot &&
                  (!selectedUsers || !selectedUsers.length) &&
                  !loading
              "
            >
              {{ $i18n.locale === 'fr' ? 'Aucun utilisateur' : 'No user' }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Visiblity',
  data() {
    return {
      selectedDepot: null,
      selectedUser_id: null,
      availableUsers: [],
      selectedUsers: [],
      selectedUser: null,
      selectedCheckbox: null,
      initLoading: true,
      loading: false,
      error: []
    }
  },
  methods: {
    ...mapActions([
      'getUsersVisibilityByDepot',
      'getteamsfiliale',
      'getAvailableVisibilityUsersByDepot',
      'addUtilisateur',
      'updateCanSee',
      'updateCanEdit'
    ]),

    nameWithLang({ name, type }) {
      let formatType = ''
      switch (type) {
        case 'user.admin':
          formatType = 'Utilisateur Admin'
          break
        case 'user.agent':
          formatType = 'Agent'
          break
        case 'user.resp.plan':
          formatType = 'Responsable Planning'
          break
        case 'user.supervisor':
          formatType = 'Régie Superviseur'
          break
        case 'user.commercial':
          formatType = 'Régie Commercial'
          break
        case 'user.final':
          formatType = 'Régie Utilisateur Final'
          break
        case 'user.technicien':
          formatType = 'Filiale Poseur'
          break
        case 'user.administration':
          formatType = 'GE Administration'
          break
        case 'user.client':
          formatType = 'Client'
          break
        case 'user.commercial.ite':
          formatType = 'Filiale Commercial'
          break
        default:
          formatType = ''
          break
      }
      return `${name} (${formatType})`
    },
    async handleClickDepots(depot_id) {
      this.loading = true
      this.error = []
      this.selectedDepot = depot_id
      this.availableUsers = []
      this.availableUsers = await this.getAvailableVisibilityUsersByDepot({
        id: depot_id,
        exclude: [
          'team',
          'technicianPipe',
          'visibleTeams',
          'commercialItePipe',
          'userPipe'
        ]
      })
      this.selectedUsers = []
      this.selectedUsers = await this.getUsersVisibilityByDepot(depot_id)
      this.loading = false
    },
    async handleChangeChekboxRead(user_id) {
      this.loading = true
      this.error = []
      this.selectedCheckbox = await this.updateCanSee({
        depot_id: this.selectedDepot,
        user_id: user_id,
        can_see: this.selectedUsers.can_see === 1 ? 1 : 0
      })
      this.selectedUsers = await this.getUsersVisibilityByDepot(
        this.selectedDepot
      )
      this.availableUsers = await this.getAvailableVisibilityUsersByDepot({
        id: this.selectedDepot,
        exclude: [
          'team',
          'technicianPipe',
          'visibleTeams',
          'commercialItePipe',
          'userPipe'
        ]
      })
      this.loading = false
    },
    async handleChangeChekboxWrite(user) {
      this.loading = true
      this.error = []
      const response = (this.selectedCheckbox = await this.updateCanEdit({
        depot_id: this.selectedDepot,
        user_id: user.id,
        can_edit: user.can_edit === 1 ? 0 : 1
      }))
      if (response && response.success == false) {
        this.error = response.error
      }
      this.selectedUsers = await this.getUsersVisibilityByDepot(
        this.selectedDepot
      )
      this.loading = false
    },
    async handleSelect(e) {
      this.loading = true
      this.error = []
      const response = await this.addUtilisateur({
        depot_id: this.selectedDepot,
        user_id: e.id
      })
      this.loading = false
      if (response.success) {
        this.selectedUser = null
        this.selectedUsers = await this.getUsersVisibilityByDepot(
          this.selectedDepot
        )
        this.availableUsers = await this.getAvailableVisibilityUsersByDepot({
          id: this.selectedDepot,
          exclude: [
            'team',
            'technicianPipe',
            'visibleTeams',
            'commercialItePipe',
            'userPipe'
          ]
        })
      } else {
        this.loading = false
        this.error = response.error
      }
    }
  },
  computed: {
    ...mapGetters(['teamsfiliale']),

    computedAvailableUsers: function() {
      return this.availableUsers.filter(
        user => this.selectedUsers.findIndex(item => item.id === user.id) < 0
      )
    }
  },
  async mounted() {
    await this.getteamsfiliale({
      exclude: [
        'depots.accessedByUsers',
        'depots.commercialsIte',
        'depots.departments',
        'depots.editibleByUsers',
        'depots.poseurs',
        'depots.team',
        'depots.visibleByUsers',
        'manager',
        'members'
      ]
    })
    this.initLoading = false
  },
  filters: {
    typeFormat: value => {
      switch (value) {
        case 'user.admin':
          return 'Utilisateur Admin'
        case 'user.agent':
          return 'Agent'
        case 'user.resp.plan':
          return 'Responsable Planning'
        case 'user.supervisor':
          return 'Régie Superviseur'
        case 'user.commercial':
          return 'Régie Commercial'
        case 'user.final':
          return 'Régie Utilisateur Final'
        case 'user.technicien':
          return 'Filiale Poseur'
        case 'user.administration':
          return 'GE Administration'
        case 'user.client':
          return 'Client'
        default:
          return ''
      }
    }
  }
}
</script>
<style lang="scss">
.visibility-planning-setting {
  .utilisateur {
    .select-users {
      .v-text-field__details {
        display: none !important;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.visibility-planning-setting {
  .groupe {
    .margin {
      margin: 12px;
    }
    .title {
      padding: 10px 0px;
    }
    .card-list {
      box-shadow: unset !important;
      .list-ensemble-depot {
        padding: 0px 0px;
        box-shadow: unset !important;
        .v-list-item {
          border-top: 0px solid #d8d8d8;
          border-bottom: 0px solid #d8d8d8;
          border-left: 0px solid #d8d8d8;
          border-right: 0px solid #d8d8d8;
          border-color: #d8d8d8 !important;
        }
      }
    }

    .list-users {
      max-height: calc(100vh - 300px) !important;
      height: calc(100vh - 300px) !important;
      margin-bottom: 0;
      overflow-y: auto;
      overflow-x: auto;
      padding: 0px 0px;
      cursor: pointer;
      border-top: 1px solid #d8d8d8;
      .list-ensemble-item {
        border-top: 0px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
        border-left: 1px solid #d8d8d8;
        border-right: 1px solid #d8d8d8;
        border-color: #d8d8d8 !important;
      }
      .list-ensemble {
        padding: 0px 0px;
        box-shadow: unset !important;
      }
    }
    .list-users::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    .list-users::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 4px;
      background-color: rgba(238, 238, 238, 1) !important;
    }
    .list-users::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(189, 189, 189, 1);
    }
  }
  .utilisateur {
    .title {
      padding: 10px 0px;
    }
    .select-users {
      .v-text-field__details {
        display: none !important;
      }
    }
    .bloc-select-multi {
      .postion-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: -19px;
        .button-add-users {
          font-size: 11px;
        }
      }
    }
    .addListUsers {
      .chargement {
        text-align: center;
        color: #2dabe2;
      }
      .list-item-user {
        padding: 0px 0 !important;

        .v-list-item__content {
          padding: 10px 0px !important;
        }
        .custom-list-group {
          border-top: 0px solid #d8d8d8;
          border-bottom: 1px solid #d8d8d8;
          border-left: 1px solid #d8d8d8;
          border-right: 1px solid #d8d8d8;
        }
      }

      .list-item-user {
        max-height: calc(100vh - 386px) !important;
        height: calc(100vh - 386px) !important;
        margin-bottom: 0;
        overflow-y: auto;
        overflow-x: auto;
        &.refact-height {
          max-height: calc(100vh - 440px) !important;
          height: calc(100vh - 440px) !important;
          margin-bottom: 0;
          overflow-y: auto;
          overflow-x: auto;
        }
      }
      .list-item-user::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
      .list-item-user::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #f0eef8;
        border-radius: 4px;
        background-color: rgba(238, 238, 238, 1) !important;
      }
      .list-item-user::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(189, 189, 189, 1);
      }
    }
  }
}
</style>
